<template>
    <div class="class-manage-cloud-marking-scroll-wrapper">
        <div class="img-wrapper">
            <img class="img" :src="cloudMarkingBg" alt="img" />
        </div>
        <div class="jump-router">
            <p class="cloud-marking-large">云阅卷平台</p>
            <p class="cloud-marking">云阅卷平台</p>
            <div class="btn" @click="jumpCloudMarking">
                <img class="img" :src="cloudMarking" alt="云阅卷" />
            </div>
        </div>
    </div>
</template>
<script>
import { getToken } from '@/libs/auth.js';

export default {
    name: "ClassManageCloudMarkingScrollWrapper",
    data () {
        return {
            order: {
                userToken: '',
                ipPort: ''
            },
            requestUri: ''
        }
    },
    created () {
        this.init();
    },
    computed: {
        cloudMarkingBg () {
            return require('@/assets/images/cloudMarking/cloud-marking-bg.png')
        },
        cloudMarking () {
            return require('@/assets/images/cloudMarking/cloud-marking-btn.png')
        }
    },
    methods: {
        /**
         * 初始化页面数据
         * initSetPagesData 初始化页面数据
         * checkHandler 赋值请求地址参数
         * */ 
        init () {
            this.initSetPagesData();
        },
        initSetPagesData () {
            this.order.userToken = getToken();
            this.checkHandler()
            console.log(this.order,'this.order')
        },

        /**
         * 跳转
         * */ 
        jumpCloudMarking () { 
            let uri = this.requestUri + `userToken=${this.order.userToken}&ipPort=${this.order.ipPort}`;
            const _window = window.open();
            console.log(uri,'uri')
                if (_window) {
                    _window.location.href = uri
                } else {
                    const a = document.createElement('a')
                    a.href = uri;
                    document.body.appendChild(a)
                    a.click()
                    document.body.removeChild(a)
                }
        },
        checkHandler () {
            // 测试：http://119.3.222.122:40003
            // 生产：http://120.46.135.106:40003
            if (process.env.NODE_ENV === "development") {
                this.order.ipPort = 'http://119.3.222.122:40003';
                this.requestUri = 'http://124.70.101.2:88/Home/DyxuserLogin?';
            }else {
                this.order.ipPort = 'http://120.46.135.106:40003';
                this.requestUri = 'http://exam.xinkaoyun.com:96/Home/DyxuserLogin?';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .class-manage-cloud-marking-scroll-wrapper {
        width: 100%;
        height: calc(100vh - 108px);
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: linear-gradient(30deg, #2F77BF 0%, #33CCBF 100%);
        .img-wrapper {
            width: 644px;
            height: 576px;
            .img {
                width: 100%;
                height: 100%;
            }
        }
        .jump-router {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-end;
            .cloud-marking-large {
                font-size: 88px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                opacity: 0.08;
            }
            .cloud-marking {
                font-size: 48px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
            }
            .btn {
                width: 260px;
                height: 72px;
                margin-top: 32px;
                cursor: pointer;
                .img {
                    width: 100%;
                    height: 100%;    
                }
            }
        }

    }
</style>