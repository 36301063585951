<!--
 * @Author: 张冰华 690227436@qq.com
 * @Date: 2024-07-22 17:04:54
 * @LastEditors: 张冰华 690227436@qq.com
 * @LastEditTime: 2024-07-23 09:31:57
 * @FilePath: \cloud_campus_Front-end\school_end\src\components\scrollWrapper\ClassManageExamination.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="class-manage-examination">
        <component
            :is="components[idx]"
            :data="detailObj" 
            @detail="handleDetail"
            @back="handleBack"
        />
    </div>
</template>

<script>
export default {
    name: 'ClassManageExamination',
    components: {
        ExaminationList: () => import(/* webpackChunkName:'ExaminationList' */"./ClassManageExamination/ExaminationList.vue"),
        ExaminationDetail: () => import(/* webpackChunkName:'ExaminationDetail' */"./ClassManageExamination/ExaminationDetail.vue"),
    },
    data() {
        return {
            components: ['ExaminationList', 'ExaminationDetail'],
            // components: ['ExaminationList', 'ExaminationDetail'],
            idx: 0,
            detailObj: {}
        }
    },
    created() {
    },
    methods: {
        handleDetail(data) {
            this.idx = 1;
            this.detailObj = data;
        },
        handleBack() {
            this.idx = 0;
        }
    },
}
</script>

<style lang="scss" scoped>
    .class-manage-examination {
        padding-top: 10px;
    }
</style>