<!-- 智慧教学-课程管理-课时统计 -->
<template>
    <div class="main-page">
        <!-- 课时统计 -->
        <tabbed-page
            v-show="showTab"
            :tabList="tabList"
            @changeTabs="changeTabs"
        >
        </tabbed-page>
        <div class="page-box">
			<component :is="comps[idx]" @switchClick="switchClick" @checkingInIdChange="checkingInIdChange" @change="handleChangeComp" @setPageInitTabs="setPageInitTabs"
				@routerReturnChange="routerReturnChange" :checkingInId="checkingInId" :query="query" :routerReturn="routerReturn">
			</component>
		</div>
    </div>
</template>

<script>
    import TabbedPage from './Sub/TabbedPage/index.vue';
    import { tabPageHasAuth } from '@/libs/tabbedPageAuth.js';
    export default {
        name: "ClassHourStatistics",
        components: {
			ClassHourStatisticsDetail: () => import(/* webpackChunkName:'ClassHourStatisticsDetail' */"./ClassHourStatistics/ClassHourStatisticsDetail.vue"),
			ClassHourStatisticsList: () => import(/* webpackChunkName:'ClassHourStatisticsList' */"./ClassHourStatistics/ClassHourStatisticsList.vue"),
			ClassHourStatisticsListWeek: () => import(/* webpackChunkName:'ClassHourStatisticsListWeek' */"./ClassHourStatistics/ClassHourStatisticsListWeek.vue"),
            ClassHourStatisticsListMonth: () => import(/* webpackChunkName:'ClassHourStatisticsListMonth' */"./ClassHourStatistics/ClassHourStatisticsListMonth.vue"),
            ClassHourStatisticsListYear:()=>import (/* webpackChunkName:'ClassHourStatisticsListYear' */"./ClassHourStatistics/ClassHourStatisticsListYear.vue"),
            TabbedPage
		},
        data() {
            return {
                currentComp: "",
                currentComponent: "",
                checkingInId: undefined,
				routerReturn: undefined,
                comps: [
                    'ClassHourStatisticsDetail',
                    'ClassHourStatisticsList',
                    'ClassHourStatisticsListWeek',
                    'ClassHourStatisticsListMonth',
                    'ClassHourStatisticsListYear',
                ],
                tabList: [
                    {
                        id: 0,
                        title: '课时总计',
                        vHasPermi: ['teachingResearchCourseLessonTimeStatistics:all'],
                        com: 'ClassHourStatisticsList'
                    },
                    {
                        id: 1,
                        title: '本周报表',
                        vHasPermi: ['teachingResearchCourseLessonTimeStatistics:week'],
                        com: 'ClassHourStatisticsListWeek'
                    },
                    {
                        id: 2,
                        title: '本月报表',
                        vHasPermi: ['teachingResearchCourseLessonTimeStatistics:month'],
                        com: 'ClassHourStatisticsListMonth',
                    },
                    {
                        id: 3,
                        title: '本年报表',
                        vHasPermi: ['teachingResearchCourseLessonTimeStatistics:year'],
                        com: 'ClassHourStatisticsListYear',
                    },
                ],
                idx: 0,
                query: {},
                formData: {},
                type: 'list',
                showTab: true
            }
        },
        created() {
            const permissions = this.$store.state.permissions
            if (permissions.includes("teachingResearchCourseLessonTimeStatistics:all")) {
                this.currentComp = "ClassHourStatisticsList"
            } else if (permissions.includes("teachingResearchCourseLessonTimeStatistics:week")) {
                this.currentComp = "ClassHourStatisticsListWeek"
            } else if (permissions.includes("teachingResearchCourseLessonTimeStatistics:month")) {
                this.currentComp = "ClassHourStatisticsListMonth"
            } else if (permissions.includes("teachingResearchCourseLessonTimeStatistics:year")) {
                this.currentComp = "ClassHourStatisticsListYear"
            }
            this.init();
        },
        activated() {
            this.setGlobalPageType(this.type)
        },
        methods: {
            init () {
                this.tabList = tabPageHasAuth(this.tabList);
                this.comps = this.tabList.map(item => item.com)
                this.comps.push('ClassHourStatisticsDetail')
            },
            /**
             * @Description: 切换tab
             * @DoWhat:
             * @UseScenarios:
             * @Attention:
             * @Author: 武东超
             * @Date: 2023-08-16 10:01:06
             */
            changeTabs (data) {
                this.tabList.forEach((item, index) => {
                    if (item.id === data.id) {
                        this.$set(item, 'sel', true)
                        this.idx = index;
                    } else {
                        this.$set(item, 'sel', false)
                    }
                })
            },
			switchClick(page) {
				this.currentComponent = page
			},
			checkingInIdChange(id) {
				this.checkingInId = id
			},
			routerReturnChange(path) {
				this.routerReturn = path
			},
            handlerChangeTab(e) {
                this.currentComp = e.compName;
            },
            handleChangeComp(e) {
                e.showTab ? this.showTab = e.showTab : this.showTab = false;
                this.currentComp = e.compName;
                this.comps.forEach((item,index) => {
                    if(item == e.compName){
                        this.idx = index;
                    }
                })
                this.query = e.query;
                this.setGlobalPageType(e.compName === 'ClassHourStatisticsDetail' ? 'detail' : 'list')
            },
            setGlobalPageType (type) {
                this.type = type
                this.$eventDispatch('setGlobalPageType', type)
            },
            setPageInitTabs() {
                this.tabList.forEach((item, index) => {
                    index == 0 && (item.sel = true);
                    index != 0 && (item.sel = false);
                })
                this.showTab = true;
                this.idx = 0;
                this.$eventDispatch('setGlobalPageType', 'list');
            }
        }
    }
</script>

<style lang="scss" scoped>
.content-main{
    margin-top: 0;
}
.tabs-list {
    display: flex;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #fff;
    border-bottom: 1px solid #EDF0F2;
    border-radius: 4px 0 0 4px;

    .tabs-item {
        width: 100px;
        position: relative;
        text-align: center;
        font-size: 16px;
        color: #333333;
        cursor: pointer;
    }

    .tabs-item.current {
        color: #2B2F33;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 25%;
            width: 50%;
            height: 2px;
            background-color: #409eff;
        }
    }
}
</style>
